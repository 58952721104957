<template>
  <section class="bg-light h-100 p-3">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="m-0">Liste des collaborateurs</h2>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#modalAdd"
      >
        Ajouter
      </button>
    </div>
    <hr />
    <div>
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Identification</th>
            <th scope="col">Email</th>
            <th scope="col">Téléphone</th>
            <th scope="col">Active</th>
            <th scope="col">Date de création</th>
            <th class="text-center" scope="col" colspan="1">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in pageOfItems" :key="item.id_collab">
            <th scope="row">{{ item.id_collab }}</th>
            <td>{{ item.fname_collab }}</td>
            <td>{{ item.email_collab }}</td>
            <td>{{ item.tel_collab }}</td>
            <td>
              <div
                class="form-check form-switch cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#switchActive"
                @click="updateIsActive(item.id_collab, item.isActive)"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="item.isActive"
                  :value="item.isActive"
                />
              </div>
            </td>
            <td>
              {{
                moment(item.date).calendar(null, {
                  sameWeek: "ddd",
                  sameElse: "DD MMMM YYYY",
                  sameDay: "[Aujourd'hui]",
                  nextDay: "[Demain]",
                  lastWeek: "dddd [dernier]",
                  nextWeek: "dddd",
                  lastDay: "[Hier]",
                })
              }}
            </td>

            <td class="d-flex justify-content-evenly gap-2">
              <div
                class="dropdown"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Modifier"
              >
                <div>
                  <IcoPen
                    data-bs-toggle="modal"
                    data-bs-target="#modalUpdate"
                    class="cursor-pointer"
                    @click="initUpdateInfoCollab(item)"
                  />
                </div>
              </div>
              <div
                class="dropdown"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Modifier le mot de passe"
              >
                <div>
                  <IcoLock
                    data-bs-toggle="modal"
                    data-bs-target="#modalUpdatePwd"
                    class="cursor-pointer"
                    @click="initUpdatePwdCollab(item)"
                  />
                </div>
              </div>
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Supprimer"
              >
                <IcoDelete
                  class="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  @click="initDeleteCollab(item.id_collab)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- modal add début-->
    <div
      class="modal fade"
      data-bs-backdrop="static"
      id="modalAdd"
      tabindex="-1"
      aria-labelledby="modalLabelAdd"
      aria-hidden="true"
    >
      <ModalAddCollab @fetch="fetch" />
    </div>
    <!-- modal add fin-->

    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Êtes-vous sûr ?
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                Le compte du collaborateur sera supprimé définitivement
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <button
              v-if="loading.delete"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              @click="deleteCollaborateur()"
              class="btn btn-primary ms-3"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal delete fin-->

    <!-- modal update switch début-->
    <div
      class="modal fade"
      id="switchActive"
      tabindex="-1"
      aria-labelledby="modalLabelswitchActive"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              {{ this.switchdata.value ? "Désactiver" : "Activer" }} le compte ?
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                Le compte sera
                {{ this.switchdata.value ? "désactivé" : "activé" }}.
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseActive"
            >
              Annuler
            </button>
            <div
              v-if="loading.update"
              class="mx-5 spinner-border text-warning"
              role="status"
            ></div>
            <button v-else @click="switchActive()" class="btn btn-primary ms-3">
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal switch fin-->

    <!-- modal update début-->
    <div
      class="modal fade"
      id="modalUpdate"
      tabindex="-1"
      aria-labelledby="modalLabelmodalUpdate"
      aria-hidden="true"
    >
      <ModalUpdateInfoCollab :dataUpdate="dataUpdate" @fetch="fetch" />
    </div>

    <!-- modal update fin-->

    <!-- modal pwd début-->
    <div
      class="modal fade"
      id="modalUpdatePwd"
      tabindex="-1"
      aria-labelledby="modalLabelmodalUpdatePwd"
      aria-hidden="true"
    >
      <ModalUpdatePwdCollab :dataUpdate="dataUpdatePwd" @fetch="fetch" />
    </div>
    <!-- modal pwd fin-->
    <div class="d-flex justify-content-center">
      <CustomPagination
        :pageSize="5"
        :items.sync="initialData"
        @changePage="onChangePage"
      ></CustomPagination>
    </div>
  </section>
</template>

<script>
import IcoLock from "vue-material-design-icons/Lock.vue";
import IcoDelete from "vue-material-design-icons/Delete.vue";
import IcoPen from "vue-material-design-icons/Pen.vue";
import { getCollaborateur } from "../../api/collaborateur";
import ModalAddCollab from "../../components/admin/ModalAddCollab.vue";
import { switchActiveCollaborateur } from "../../api/collaborateur";
import { success } from "../../utils/toast";
import { deleteCollaborateur } from "../../api/collaborateur";
import ModalUpdateInfoCollab from "../../components/admin/ModalUpdateInfoCollab.vue";
import ModalUpdatePwdCollab from "../../components/admin/ModalUpdatePwdCollab.vue";
import CustomPagination from "../../components/public/Pagination.vue";

export default {
  name: "OffreListe",
  components: {
    ModalUpdatePwdCollab,
    ModalUpdateInfoCollab,
    IcoLock,
    IcoDelete,
    ModalAddCollab,
    IcoPen,
    CustomPagination,
  },
  data() {
    return {
      data: [],
      idDelete: 0,
      switchdata: { value: null, id_collab: null },
      loading: {
        data: true,
        update: false,
        delete: false,
      },
      dataUpdate: {},
      dataUpdatePwd: {},
      pageOfItems: [],
      initialData: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCollaborateur(this.$route.params.id).then((result) => {
        this.loading.data = false;
        this.initialData = result.data;
      });
    },
    initDeleteCollab(id) {
      this.idDelete = id;
    },
    initUpdateInfoCollab(data) {
      this.dataUpdate = data;
    },
    initUpdatePwdCollab(data) {
      this.dataUpdatePwd = data;
    },
    switchActive() {
      switchActiveCollaborateur(this.switchdata).then((result) => {
        this.$refs.CloseActive.click();
        success(
          `Le compte est maintenant ${
            result.data.isActive ? "activé" : "désactivé"
          }`
        );
        this.fetch();
      });
    },
    deleteCollaborateur() {
      this.loading.delete = true;
      deleteCollaborateur(this.idDelete).then(() => {
        this.fetch();
        this.loading.delete = false;
        this.$refs.CloseDelete.click();
        success("Collaborateur supprimée");
      });
    },
    updateIsActive(id, val) {
      this.switchdata.id_collab = id;
      this.switchdata.value = val;
    },
    onChangePage(pageOfItems) {
      window.scrollTo(0, 0);
      this.pageOfItems = pageOfItems;
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  pointer-events: none;
}
</style>
