<template>
  <!-- modal add début-->

  <div class="modal-dialog modal-lg">
    <form
      @submit.prevent="confirm"
      class="modal-content border-0 bg-light text-dark"
      autocomplete="off"
    >
      <div class="modal-header mx-2">
        <h4 class="modal-title text-primary" id="modalLabelAjoutCollab">
          Ajouter un nouveau collaborateur
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <section class="row p-2">
          <div class="col-12 mb-3">
            <label class="form-label">Identification</label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              required
              v-model="form.fname_collab"
            />
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              autocomplete="off"
              required
              v-model="form.email_collab"
            />
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Téléphone</label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              required
              v-model="form.tel_collab"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label">Mot de passe</label>
            <input
              type="password"
              class="form-control"
              autocomplete="new-password"
              required
              v-model="form.pwd_collab"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label">Confirmer le mot de passe</label>
            <input type="password" autocomplete="off" class="form-control" />
          </div>
        </section>
      </div>
      <div class="modal-footer mx-2">
        <button
          type="button"
          class="btn btn-secondary me-0"
          data-bs-dismiss="modal"
          ref="close"
        >
          Annuler
        </button>
        <button v-if="loading" class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Envoi...
        </button>
        <button v-else type="submit" class="btn btn-primary ms-3">
          Confirmer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { createCollaborateur } from "../../api/collaborateur";
import { success, error } from "../../utils/toast";

export default {
  name: "ModalAddCollab",
  data() {
    return {
      form: {
        fname_collab: "",
        email_collab: "",
        tel_collab: "",
        pwd_collab: "",
      },
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.loading = true;
      createCollaborateur(this.form).then((result) => {
        if (result.data.error) {
          this.loading = false;
          error(result.data.error, "");
        } else {
          this.loading = false;
          this.$refs.close.click();
          success("Collaborateur ajouté");
          this.$emit("fetch");
        }
      });
    },
  },
};
</script>
