<template>
  <!-- modal add début-->

  <div class="modal-dialog modal-lg modal-dialog-centered">
    <form
      @submit.prevent="confirm"
      class="modal-content border-0 bg-light text-dark"
    >
      <div class="modal-header mx-2">
        <h4 class="modal-title text-primary" id="modalLabelUpdate">
          Modifier les informations de login {{ dataUpdate.fname_collab }}
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <section class="row p-2">
          <div class="col-md-6 mb-3">
            <label for="mdp" class="form-label">Mot de passe</label>
            <input
              type="password"
              class="form-control"
              required
              v-model="form.pwd_collab"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="confirm-mdp" class="form-label"
              >Confirmer le mot de passe</label
            >
            <input type="password" class="form-control" />
          </div>
        </section>
      </div>
      <div class="modal-footer mx-2">
        <button
          type="button"
          class="btn btn-secondary me-0"
          data-bs-dismiss="modal"
          ref="close"
        >
          Annuler
        </button>
        <button v-if="loading" class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Envoi...
        </button>
        <button v-else type="submit" class="btn btn-primary ms-3">
          Confirmer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { updatePwdCollaborateur } from "../../api/collaborateur";
import { success, error } from "../../utils/toast";

export default {
  name: "ModalAddCollab",
  props: {
    dataUpdate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: { pwd_collab: "", confirm_pwd_collab: "" },
      infoCollab: {},
      loading: false,
    };
  },
  watch: {
    dataUpdate: {
      handler(val) {
        //  unused
        this.form = { pwd_collab: "", confirm_pwd_collab: "" };
        this.infoCollab = { ...val };
      },
      deep: true,
    },
  },
  methods: {
    confirm() {
      this.loading = true;
      updatePwdCollaborateur(this.dataUpdate.id_collab, this.form).then(
        (result) => {
          if (result.data.error) {
            this.loading = false;
            error(result.data.error, "");
          } else {
            this.loading = false;
            this.$refs.close.click();
            success("Collaborateur modifié");
            this.$emit("fetch");
          }
        }
      );
    },
  },
};
</script>
